/*
 * QQQ - Low-code Application Framework for Engineers.
 * Copyright (C) 2021-2022.  Kingsrook, LLC
 * 651 N Broad St Ste 205 # 6917 | Middletown DE 19709 | United States
 * contact@kingsrook.com
 * https://github.com/Kingsrook/
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */


import colors from "qqq/components/legacy/colors";

function pxToRem(number: number, baseNumber: number = 16): string
{
   return `${number / baseNumber}rem`;
}

const {dark} = colors;

// types
interface DisplayTypes
{
   fontFamily: string;
   color: string;
   fontWeight: number;
   lineHeight: number;
   fontSize: string;
}

interface Types
{
   fontFamily: string;
   fontWeightLighter: number;
   fontWeightLight: number;
   fontWeightRegular: number;
   fontWeightMedium: number;
   fontWeightBold: number;
   h1: {
      fontFamily: string;
      fontSize: string;
      fontWeight: number;
      color: string;
      lineHeight: number;
   };
   h2: {
      fontFamily: string;
      fontSize: string;
      fontWeight: number;
      color: string;
      lineHeight: number;
   };
   h3: {
      fontFamily: string;
      fontSize: string;
      fontWeight: number;
      color: string;
      lineHeight: number;
   };
   h4: {
      fontFamily: string;
      fontSize: string;
      fontWeight: number;
      color: string;
      lineHeight: number;
   };
   h5: {
      fontFamily: string;
      fontSize: string;
      fontWeight: number;
      color: string;
      lineHeight: number;
   };
   h6: {
      fontFamily: string;
      fontSize: string;
      fontWeight: number;
      color: string;
      lineHeight: number;
   };
   subtitle1: {
      fontFamily: string;
      fontSize: string;
      fontWeight: number;
      lineHeight: number;
   };
   subtitle2: {
      fontFamily: string;
      fontSize: string;
      fontWeight: number;
      lineHeight: number;
   };
   body1: {
      fontFamily: string;
      fontSize: string;
      fontWeight: number;
      lineHeight: number;
   };
   body2: {
      fontFamily: string;
      fontSize: string;
      fontWeight: number;
      lineHeight: number;
   };
   button: {
      fontFamily: string;
      fontSize: string;
      fontWeight: number;
      lineHeight: number;
      textTransform: any;
   };
   caption: {
      fontFamily: string;
      fontSize: string;
      fontWeight: number;
      lineHeight: number;
   };
   overline: {
      fontFamily: string;
   };
   d1: DisplayTypes;
   d2: DisplayTypes;
   d3: DisplayTypes;
   d4: DisplayTypes;
   d5: DisplayTypes;
   d6: DisplayTypes;
   size: {
      xxs: string;
      xs: string;
      sm: string;
      md: string;
      lg: string;
      xl: string;
      "2xl": string;
      "3xl": string;
   };
   lineHeight: {
      sm: number;
      md: number;
      lg: number;
   };
}

const baseProperties = {
   fontFamily: "\"SF Pro Display\", \"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
   fontWeightLighter: 100,
   fontWeightLight: 300,
   fontWeightRegular: 400,
   fontWeightMedium: 600,
   fontWeightBold: 700,
   fontSizeXXS: pxToRem(10.4),
   fontSizeXS: pxToRem(12),
   fontSizeSM: pxToRem(14),
   fontSizeMD: pxToRem(16),
   fontSizeLG: pxToRem(18),
   fontSizeXL: pxToRem(20),
   fontSize2XL: pxToRem(24),
   fontSize3XL: pxToRem(30),
};

const baseHeadingProperties = {
   fontFamily: baseProperties.fontFamily,
   color: dark.main,
   fontWeight: baseProperties.fontWeightBold,
};

const baseDisplayProperties = {
   fontFamily: baseProperties.fontFamily,
   color: dark.main,
   fontWeight: baseProperties.fontWeightLight,
   lineHeight: 1.2,
};

const typography: Types = {
   fontFamily: baseProperties.fontFamily,
   fontWeightLighter: baseProperties.fontWeightLighter,
   fontWeightLight: baseProperties.fontWeightLight,
   fontWeightRegular: baseProperties.fontWeightRegular,
   fontWeightMedium: baseProperties.fontWeightMedium,
   fontWeightBold: baseProperties.fontWeightBold,

   h1: {
      fontSize: pxToRem(48),
      lineHeight: 1.25,
      ...baseHeadingProperties,
   },

   h2: {
      fontSize: pxToRem(36),
      lineHeight: 1.3,
      ...baseHeadingProperties,
   },

   h3: {
      fontSize: pxToRem(30),
      lineHeight: 1.375,
      ...baseHeadingProperties,
   },

   h4: {
      fontSize: pxToRem(24),
      lineHeight: 1.375,
      ...baseHeadingProperties,
   },

   h5: {
      fontSize: pxToRem(20),
      lineHeight: 1.375,
      ...baseHeadingProperties,
   },

   h6: {
      fontSize: pxToRem(16),
      lineHeight: 1.625,
      ...baseHeadingProperties,
   },

   subtitle1: {
      fontFamily: baseProperties.fontFamily,
      fontSize: baseProperties.fontSizeXL,
      fontWeight: baseProperties.fontWeightLight,
      lineHeight: 1.625,
   },

   subtitle2: {
      fontFamily: baseProperties.fontFamily,
      fontSize: baseProperties.fontSizeMD,
      fontWeight: baseProperties.fontWeightLight,
      lineHeight: 1.6,
   },

   body1: {
      fontFamily: baseProperties.fontFamily,
      fontSize: baseProperties.fontSizeXL,
      fontWeight: baseProperties.fontWeightRegular,
      lineHeight: 1.625,
   },

   body2: {
      fontFamily: baseProperties.fontFamily,
      fontSize: baseProperties.fontSizeMD,
      fontWeight: baseProperties.fontWeightLight,
      lineHeight: 1.6,
   },

   button: {
      fontFamily: baseProperties.fontFamily,
      fontSize: baseProperties.fontSizeSM,
      fontWeight: baseProperties.fontWeightLight,
      lineHeight: 1.5,
      textTransform: "uppercase",
   },

   caption: {
      fontFamily: baseProperties.fontFamily,
      fontSize: baseProperties.fontSizeXS,
      fontWeight: baseProperties.fontWeightLight,
      lineHeight: 1.25,
   },

   overline: {
      fontFamily: baseProperties.fontFamily,
   },

   d1: {
      fontSize: pxToRem(80),
      ...baseDisplayProperties,
   },

   d2: {
      fontSize: pxToRem(72),
      ...baseDisplayProperties,
   },

   d3: {
      fontSize: pxToRem(64),
      ...baseDisplayProperties,
   },

   d4: {
      fontSize: pxToRem(56),
      ...baseDisplayProperties,
   },

   d5: {
      fontSize: pxToRem(48),
      ...baseDisplayProperties,
   },

   d6: {
      fontSize: pxToRem(40),
      ...baseDisplayProperties,
   },

   size: {
      xxs: baseProperties.fontSizeXXS,
      xs: baseProperties.fontSizeXS,
      sm: baseProperties.fontSizeSM,
      md: baseProperties.fontSizeMD,
      lg: baseProperties.fontSizeLG,
      xl: baseProperties.fontSizeXL,
      "2xl": baseProperties.fontSize2XL,
      "3xl": baseProperties.fontSize3XL,
   },

   lineHeight: {
      sm: 1.25,
      md: 1.5,
      lg: 2,
   },
};

export default typography;
