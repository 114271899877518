/**
 =========================================================
 * Material Dashboard 2 PRO React TS - v1.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Material Dashboard 2 PRO React TS Base Styles
import colors from "qqq/assets/theme/base/colors";
import borders from "qqq/assets/theme/base/borders";
import boxShadows from "qqq/assets/theme/base/boxShadows";

// Material Dashboard 2 PRO React Helper Function
import rgba from "qqq/assets/theme/functions/rgba";

const {black, white} = colors;
const {borderWidth, borderRadius} = borders;
const {md} = boxShadows;

// types
// types
type Types = any;

const card: Types = {
   defaultProps: {
      elevation: 0
   },
   styleOverrides: {
      root: {
         display: "flex",
         flexDirection: "column",
         position: "relative",
         minWidth: 0,
         wordWrap: "break-word",
         backgroundColor: white.main,
         backgroundClip: "border-box",
         border: `${borderWidth[1]} solid ${colors.grayLines.main}`,
         borderRadius: borderRadius.xl,
         overflow: "visible",
      },
   },
};

export default card;
