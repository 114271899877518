/*
 * QQQ - Low-code Application Framework for Engineers.
 * Copyright (C) 2021-2022.  Kingsrook, LLC
 * 651 N Broad St Ste 205 # 6917 | Middletown DE 19709 | United States
 * contact@kingsrook.com
 * https://github.com/Kingsrook/
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

import {QInstance} from "@kingsrook/qqq-frontend-core/lib/model/metaData/QInstance";
import {GoogleOAuthProvider} from "@react-oauth/google";
import React from "react";
import {GoogleDriveFolderPicker} from "qqq/components/processes/GoogleDriveFolderPicker";

interface Props
{
   showDefaultFoldersView: boolean;
   showSharedDrivesView: boolean;
   qInstance: QInstance;
}

export function GoogleDriveFolderPickerWrapper({showDefaultFoldersView, showSharedDrivesView, qInstance}: Props): JSX.Element
{
   const clientId = qInstance.environmentValues.get("GOOGLE_APP_CLIENT_ID") || process.env.REACT_APP_GOOGLE_APP_CLIENT_ID;

   return (
      <GoogleOAuthProvider clientId={clientId}>
         <GoogleDriveFolderPicker showDefaultFoldersView={showDefaultFoldersView} showSharedDrivesView={showSharedDrivesView} qInstance={qInstance} />
      </GoogleOAuthProvider>
   );
}

GoogleDriveFolderPickerWrapper.defaultProps = {
   showDefaultFoldersView: true,
   showSharedDrivesView: true
};
